import { render, staticRenderFns } from "./Welcome.vue?vue&type=template&id=a16f5eae&scoped=true&"
import script from "./Welcome.vue?vue&type=script&lang=js&"
export * from "./Welcome.vue?vue&type=script&lang=js&"
import style0 from "@/core/styles/shared.scss?vue&type=style&index=0&id=a16f5eae&lang=scss&scoped=true&"
import style1 from "./Welcome.scss?vue&type=style&index=1&id=a16f5eae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../home/gitlab-runner/.nvm/versions/node/v12.14.1/lib/node_modules/@briskly/vue-cli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a16f5eae",
  null
  
)

export default component.exports