import searchAPI from '@/apps/market/api/search';
import { differenceInMilliseconds } from 'date-fns';
import { cloneDeep } from 'lodash';

const state = {
  input: '',
  loading: false,
  inputLastDateChange: null,
  tryToFetchDataInMilliseconds: 1000,

  categoryId: null,

  foundProducts: {
    items: [],
    meta: {},
  },
  foundCategories: {
    items: [],
    meta: {},
  },
  registeredTimers: {},
};

const initialState = cloneDeep(state);

const getters = {
  input: (state) => state.input,
  loading: (state) => state.loading,
  inputLastDateChange: (state) => state.inputLastDateChange,
  foundProducts: (state) => state.foundProducts.items,
  foundCategories: (state) => state.foundCategories.items,
  productById: (state) => (id) => state.foundProducts.items.find((item) => item.id === id),
  categoryProducts: (state) => state.categoryProducts.items.filter((v) => v.price),
};

const actions = {
  flushAction: ({ commit }) => {
    commit('flush');
  },

  setInputAction: ({ commit, state, dispatch }, { value, categoryId }) => {
    if (state.loading) return;

    commit('setInput', value);
    commit('setCategoryId', categoryId);
    commit('setInputLastDateChange');
    dispatch('registerInputChangeTimeoutAction');
  },

  registerInputChangeTimeoutAction: ({ state, dispatch, commit }) => {
    const timeoutId = Date.now();

    const timeoutHandler = () => {
      if (state.loading || !state.input || state.input.length < 3) {
        dispatch('clearAllRegisteredTimersAction');
        return;
      }

      const now = new Date();

      // если прошла секунда после последнего ввода
      const canContinue =
        differenceInMilliseconds(now, state.inputLastDateChange) >=
        state.tryToFetchDataInMilliseconds;

      if (!canContinue) return;

      dispatch('clearAllRegisteredTimersAction');
      dispatch('fetchDataAction');
    };

    const timeout = setTimeout(timeoutHandler, state.tryToFetchDataInMilliseconds);

    commit('addRegisteredTimer', { id: timeoutId, timeout });
  },

  clearAllRegisteredTimersAction: ({ state, commit }) => {
    Object.keys(state.registeredTimers).forEach((id) => {
      clearTimeout(state.registeredTimers[id]);
      commit('removeRegisteredTimer', id);
    });
  },

  fetchDataAction: ({ state, commit }) => {
    commit('setLoading', true);

    searchAPI
      .get(state.input, { categoryId: state.categoryId })
      .then(({ products, categories }) => {
        commit('setFoundCategories', categories);
        commit('setFoundProducts', products);
      })
      .catch((error) => console.error(error))
      .finally(() => commit('setLoading', false));
  },

  fetchProducts: ({ state, commit }) => {
    const { meta } = state.foundProducts;

    if (!Object.keys(meta).length) return;
    if (meta.page >= meta.total_pages) return;

    commit('setLoading', true);

    const payload = {
      page: state.foundProducts.meta.page + 1,
      limit: state.foundProducts.meta.limit,
    };

    searchAPI
      .getProducts(state.input, payload)
      .then((products) => commit('addFoundProducts', products))
      .catch((error) => console.error(error))
      .finally(() => commit('setLoading', false));
  },

  restoreAction: ({ commit }) => {
    commit('restore');
  },
};

const mutations = {
  restore: (state) => {
    state.foundProducts = {
      items: [],
      meta: {},
    };

    state.foundCategories = {
      items: [],
      meta: {},
    };
  },

  setLoading: (state, value) => {
    state.loading = value;
  },

  setInput: (state, value) => {
    state.input = value;
  },

  setCategoryId: (state, value) => {
    state.categoryId = value;
  },

  setFoundCategories: (state, value) => {
    state.foundCategories = value;
  },

  setFoundProducts: (state, value) => {
    state.foundProducts = value;
  },

  addFoundProducts: (state, { items, meta }) => {
    const all = [...state.foundProducts.items, ...items];
    const uniqueIds = new Set(all.map((i) => i.id));

    const newItems = [...uniqueIds].map((id) => all.find((i) => i.id === id));

    state.foundProducts = {
      items: newItems,
      meta,
    };
  },

  addRegisteredTimer: (state, { id, timeout }) => {
    const newRegisteredTimers = { ...state.registeredTimers, [id]: timeout };
    state.registeredTimers = newRegisteredTimers;
  },

  removeRegisteredTimer: (state, id) => {
    const newRegisteredTimers = { ...state.registeredTimers };
    delete newRegisteredTimers[id];
    state.registeredTimers = newRegisteredTimers;
  },

  setInputLastDateChange: (state) => {
    state.inputLastDateChange = new Date();
  },

  flush: (state) => {
    Object.assign(state, cloneDeep(initialState));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
