import mainAPI from '@/core/api/main';
import marketMainAPI from '@/apps/market/api/main';
import { cloneDeep } from 'lodash';
import router from '@/core/router';

const state = {
  phone: '',
  loading: false,
  customer: {},
  order: {},
  canDelivery: null,
  errors: {},
  nearestStore: null,
  nearestStores: { items: [], meta: {} },
};

const initialState = cloneDeep(state);

const getters = {
  loading: (state) => state.loading,
  phone: (state) => state.phone,
  order: (state) => state.order,
  customer: (state) => state.customer,
  errors: (state) => state.errors,
  canDelivery: (state) => state.canDelivery,
  nearestStore: (state) => state.nearestStore,
  nearestStores: (state) => state.nearestStores.items,
};

const actions = {
  setPhoneAction: ({ commit }, value) => {
    commit('setPhone', value);
  },

  setCanDeliveryAction: ({ commit }, value) => {
    commit('setCanDelivery', value);
  },

  setNearestStoreAction: ({ commit }, value) => {
    commit('setNearestStore', value);
  },

  flushCustomerDataAction: async ({ commit, getters, dispatch }, force = true) => {
    try {
      try {
        await router.replace({ name: 'main' });
      } catch {}

      const { id } = getters.order;

      await dispatch('core$main/setWelcomeStageAction', null, { root: true });
      await dispatch('market$cart/flushAction', null, { root: true });
      await dispatch('market$search/flushAction', null, { root: true });
      await dispatch('market$categories/flushAction', null, { root: true });
      commit('flushState');

      if (force) await mainAPI.deleteOrder({ id });
    } catch (error) {
      console.error(error);
    }
  },

  fetchDirectAddressAction: async ({ dispatch }, value) => {
    try {
      await dispatch('setLoadingAction', true);
      await dispatch('setCanDeliveryAction');
      await dispatch('setNearestStoreAction');

      const [target] = await mainAPI.getAddress({ q: value, limit: 1 });

      if (!target) return;

      const { address, coords_lat, coords_lng } = target;

      await dispatch('updateInfoAction', { name: 'address', value: address });
      await dispatch('updateInfoAction', { name: 'lat', value: coords_lat });
      await dispatch('updateInfoAction', { name: 'lng', value: coords_lng });
      await dispatch('handleDirectAddressAction');
    } catch (error) {
      console.error(error);
      dispatch('setLoadingAction', false);
    }
  },

  handleDirectAddressAction: async ({ commit, dispatch, getters }) => {
    const { delivery } = getters.order;
    const { coords_lat: lat, coords_lng: lng } = delivery;

    try {
      if (!lat || !lng) {
        dispatch('setCanDeliveryAction', false);
        commit('setError', { name: 'address', message: 'Адрес вне зоны доставки' });
        return;
      }

      const { is_available } = await mainAPI.isDeliveryAvailable({ lat, lng });

      dispatch('setCanDeliveryAction', is_available);

      if (!is_available) {
        commit('setError', { name: 'address', message: 'Адрес вне зоны доставки' });
        return;
      }

      const nearestStores = await mainAPI.getStoresByDistance({ lat, lng });

      commit('setNearestStores', nearestStores);

      const [nearestStore] = getters.nearestStores;

      if (!nearestStore) {
        commit('setError', {
          name: 'address',
          message: 'Адрес вне зоны доставки',
        });
      }

      await dispatch('setNearestStoreAction', nearestStore);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('setLoadingAction', false);
    }
  },

  confirmCustomerCredentialsAction: async ({ commit, getters, dispatch }) => {
    try {
      const { first_name, last_name } = getters.customer;
      const { id: order_id, delivery } = getters.order;
      const { id: store_id } = getters.nearestStore;

      const {
        address,
        address_apartment,
        address_entrance,
        address_floor,
        coords_lat,
        coords_lng,
        note,
      } = delivery;

      const updateData = {
        id: order_id,
        customer: {
          first_name,
          last_name,
        },
        delivery_address: {
          address,
          address_apartment,
          address_entrance,
          address_floor,
          coords_lat,
          coords_lng,
          note,
        },
      };

      await mainAPI.updateOrder(updateData);

      try {
        await router.replace({ name: 'main' });
      } catch {}

      await dispatch('market$cart/flushAction', null, { root: true });
      await dispatch('market$search/flushAction', null, { root: true });
      await dispatch('market$categories/flushAction', null, { root: true });
      await marketMainAPI.init({ order_id, store_id });
      await dispatch('market$cart/setOrderAction', getters.order, { root: true });
      await router.replace({ name: 'market$main' });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('setLoadingAction', false);
    }
  },

  updateInfoAction: ({ commit, dispatch }, { name, value }) => {
    commit('setError', { name, message: '' });

    switch (name) {
      case 'phone':
        dispatch('setPhoneAction', value);
        return;

      case 'lng':
        commit('setOrderLng', value);
        return;

      case 'lat':
        commit('setOrderLat', value);
        return;

      case 'lastName':
        commit('setCustomerLastName', value);
        return;

      case 'firstName':
        commit('setCustomerFirstName', value);
        return;

      case 'address':
        commit('setOrderAddress', value);
        return;

      case 'apartment':
        commit('setOrderApartment', value);
        return;

      case 'entrance':
        commit('setOrderEntrance', value);
        return;

      case 'floor':
        commit('setOrderFloor', value);
        return;

      case 'note':
        commit('setOrderNote', value);
        return;
    }
  },

  createOrderAction: async ({ commit, getters, dispatch }) => {
    try {
      const { customer, order } = await mainAPI.createOrder({ phone: getters.phone });

      commit('setOrder', order);
      commit('setCustomer', customer);
      dispatch('core$main/setNewOrderStageAction', null, { root: true });
    } catch (error) {
      console.error(error);
    }
  },

  setLoadingAction: ({ commit }, value) => {
    commit('setLoading', value);
  },
};

const mutations = {
  setLoading: (state, value) => {
    state.loading = value;
  },

  flushState: (state) => {
    Object.assign(state, cloneDeep(initialState));
  },

  setCanDelivery: (state, value) => {
    state.canDelivery = value;
  },

  setNearestStore: (state, value) => {
    state.nearestStore = value;
  },

  setNearestStores: (state, value) => {
    state.nearestStores = value;
  },

  setPhone: (state, value) => {
    state.phone = value;
  },

  setCustomer: (state, value) => {
    state.customer = value;
  },

  setOrder: (state, value) => {
    state.order = value;
  },

  setOrderEntrance: (state, value) => {
    const updatedOrder = { ...state.order };
    updatedOrder.delivery.address_entrance = value;
    state.order = updatedOrder;
  },

  setOrderApartment: (state, value) => {
    const updatedOrder = { ...state.order };
    updatedOrder.delivery.address_apartment = value;
    state.order = updatedOrder;
  },

  setOrderLng: (state, value) => {
    const updatedOrder = { ...state.order };
    updatedOrder.delivery.coords_lng = value;
    state.order = updatedOrder;
  },

  setOrderLat: (state, value) => {
    const updatedOrder = { ...state.order };
    updatedOrder.delivery.coords_lat = value;
    state.order = updatedOrder;
  },

  setError: (state, { name, message }) => {
    const errors = { ...state.errors };
    errors[name] = message;
    state.errors = errors;
  },

  setOrderFloor: (state, value) => {
    const updatedOrder = { ...state.order };
    updatedOrder.delivery.address_floor = value;
    state.order = updatedOrder;
  },

  setCustomerLastName: (state, value) => {
    const updatedCustomer = { ...state.customer };
    updatedCustomer.last_name = value;
    state.customer = updatedCustomer;
  },

  setCustomerFirstName: (state, value) => {
    const updatedCustomer = { ...state.customer };
    updatedCustomer.first_name = value;
    state.customer = updatedCustomer;
  },

  setOrderAddress: (state, value) => {
    const updatedOrder = { ...state.order };
    updatedOrder.delivery.address = value;
    state.order = updatedOrder;
  },

  setOrderNote: (state, value) => {
    const updatedOrder = { ...state.order };
    updatedOrder.delivery.note = value;
    state.order = updatedOrder;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
