<template>
  <div>
    <component :is="computeComponent" />
  </div>
</template>

<script>
import Preloader from '@/apps/market/views/Preloader';
import { mapGetters } from 'vuex';
import mainAPI from '@/core/api/main';

export default {
  components: {
    Preloader,
  },
  computed: {
    ...mapGetters({ loading: 'core$main/loading' }),

    computeComponent() {
      if (this.loading) return 'preloader';
      return 'router-view';
    },
  },

  mounted() {
    mainAPI.init();
  },
};
</script>
