import Vue from 'vue';
import Router from 'vue-router';
import { getAppRoutes } from 'shared/core/utils';
import Main from '@/core/views/Main/Main';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      ...getAppRoutes(),
      {
        path: 'orders/:order',
        name: 'order',
        component: () => import('@/core/views/Order/Order'),
      },

      {
        path: 'info',
        name: 'info',
        component: () => import('@/core/views/InfoPage/InfoPage'),
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
});

export default router;
