import store from '@/core/store';
import { api } from 'shared/core';

class MainAPI {
  isInitialized = false;
  isInitRun = false;

  setIsInitialized = (bool) => {
    isInitialized = bool;
  };

  init = async () => {
    if (this.isInitRun) return;
    if (this.isInitialized) return;

    this.isInitRun = true;

    try {
      await store.dispatch('core$main/fetchProfile');
      await store.dispatch('core$orders/fetchInitialOrders');
      await store.dispatch('core$main/setLoadingAction', false);
      this.isInitialized = true;
    } catch (error) {
      console.error(error);
    } finally {
      this.isInitRun = false;
    }
  };

  getProfile = async () => {
    return api.endpoint.get('dashboard/profile/get');
  };

  /**
   * response: {customer: {}, order: {}}
   */
  createOrder = async (opts = {}) => {
    const { phone } = opts;

    if (!phone) throw new Error('required field:', 'phone');

    return api.endpoint.post('dashboard/concierge/order/create', {
      customer_phone: phone,
    });
  };

  getAddress = async (opts = {}) => {
    const { q, limit = 10 } = opts;

    return api.endpoint.get('dashboard/utils/address/find', {
      q,
      limit,
    });
  };

  /**
   * request body: {
    "id": 1234,
    "customer": {
      "first_name": "Иван",
      "last_name": "Иванов"
    },
    "delivery_address": {
      "address": "г Москва, Университетская пл",
      "address_apartment": "кв. 5",
      "address_entrance": "п. 2",
      "address_floor": "эт. 7",
      "coords_lat": 55.7059927,
      "coords_lng": 37.5364813,
      "note": "Налево, а затем направо."
    }
  }
   */
  updateOrder = async (opts = {}) => {
    return api.endpoint.post('dashboard/concierge/order/update', opts);
  };

  /**
   * response: { "is_available": true/false }
   */
  isDeliveryAvailable = async (opts = {}) => {
    const { lat, lng } = opts;

    if (!lat) throw new Error('required field', 'lat');
    if (!lng) throw new Error('required field', 'lng');

    return api.endpoint.get('dashboard/concierge/order/delivery/is-available', {
      coords_lat: lat,
      coords_lng: lng,
    });
  };

  /**
   * response: {distance: number, ...store}
   */
  getNearestStore = async (opts = {}) => {
    const { lat, lng } = opts;

    if (!lat) throw new Error('required field', 'lat');
    if (!lng) throw new Error('required field', 'lng');

    return api.endpoint.get('dashboard/concierge/order/store/get-nearest', {
      coords_lat: lat,
      coords_lng: lng,
    });
  };

  getStoresByDistance = async (opts = {}) => {
    const { lat, lng } = opts;

    if (!lat) throw new Error('required field', 'lat');
    if (!lng) throw new Error('required field', 'lng');

    return api.endpoint.get('dashboard/concierge/order/store/get-list-by-distance', {
      coords_lat: lat,
      coords_lng: lng,
    });
  };

  selectStore = async (opts = {}) => {
    const { storeId, orderId } = opts;

    if (!storeId) throw new Error('required field', 'storeId');
    if (!orderId) throw new Error('required field', 'orderId');

    return api.endpoint.get('dashboard/concierge/order/store/select', {
      id: orderId,
      store_id: storeId,
    });
  };

  sendOrder = async (opts = {}) => {
    const { orderId } = opts;

    if (!orderId) throw new Error('required field', 'orderId');

    return api.endpoint.post('dashboard/concierge/order/send-bind-card-link', {
      id: orderId,
    });
  };

  getOrderList = async (opts = {}) => {
    const { preset } = opts;

    return api.endpoint.get('dashboard/concierge/order/get-list', { preset });
  };

  getPackingPaper = async (opts = {}) => {
    const { order_id: id } = opts;

    return api.endpoint.get('dashboard/selected-store/order/get-packing-paper', {
      id,
    });
  };

  getOrderById = async (opts = {}) => {
    const { id } = opts;

    if (!id) throw new Error('required field', 'id');

    return api.endpoint.get('dashboard/concierge/order/get-by-id', { id });
  };

  deleteOrder = async (opts = {}) => {
    const { id } = opts;

    if (!id) throw new Error('required field', 'id');

    return api.endpoint.post('dashboard/concierge/order/delete', { id });
  };
}

export default new MainAPI();
