import store from '@/core/store';
import { api } from 'shared/core';

class CartAPI {
  isApp = localStorage.getItem('isApp');

  async setUpCurrentOrder() {
    try {
      const response = await api.endpoint.get('dashboard/selected-store/order/create');
      store.dispatch('market$cart/setOrderAction', response);
    } catch (error) {
      console.error(error);
    }
  }

  async addItem(orderId, item, quantity = 1) {
    return api.endpoint.post('dashboard/selected-store/order/item/create', {
      id: orderId,
      item_id: item.id,
      item_quantity: quantity,
    });
  }

  async updateItem(orderId, item, quantity = 1) {
    return api.endpoint.post('dashboard/selected-store/order/item/update', {
      id: orderId,
      order_item_id: item.id,
      item_quantity: quantity,
    });
  }

  async updateWeightItem(orderId, item, weight) {
    return api.endpoint.post('dashboard/selected-store/order/item/save-weight', {
      id: orderId,
      item_id: item.id,
      item_weight: weight,
    });
  }

  async removeItem(orderId, item) {
    return api.endpoint.post('dashboard/selected-store/order/item/delete', {
      id: orderId,
      order_item_id: item.id,
    });
  }

  async addWeightItem(orderId, item, weight) {
    return api.endpoint.post('dashboard/selected-store/order/item/create-with-weight', {
      id: orderId,
      item_id: item.id,
      item_weight: weight,
    });
  }

  async increaseItem(orderId, item) {
    return api.endpoint.post('dashboard/selected-store/order/item/increase', {
      id: orderId,
      order_item_id: item.id,
    });
  }

  async decreaseItem(orderId, item) {
    return api.endpoint.post('dashboard/selected-store/order/item/decrease', {
      id: orderId,
      order_item_id: item.id,
    });
  }

  async pay(orderId) {
    return api.endpoint.post('dashboard/concierge/order/send', {
      id: orderId,
    });
  }
}

export default new CartAPI();
