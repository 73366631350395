<template>
  <div class="line-input">
    <label v-if="value">{{ placeholder }}</label>

    <input
      @keydown.enter="onEnter"
      :disabled="disabled"
      @input="onChange"
      :class="{ hasIcon, wide, 'with-error': !!error }"
      :placeholder="placeholder"
      type="text"
      :value="value"
      ref="input"
    />

    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<style src="./LineInput.scss" lang="scss" scoped></style>

<script>
export default {
  props: {
    onChange: {
      type: Function,
      default: () => {},
    },
    onEnter: {
      type: Function,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: '',
    },
    wide: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    numeric: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numericRegex: '[0-9]*',
    };
  },
  computed: {
    maybeNumericRegex() {
      return this.numeric ? this.numericRegex : '';
    },
  },
};
</script>
