<template>
  <div v-if="profile" ref="topbar" class="topbar">
    <div class="employee">
      <div class="employee__avatar"></div>
      <div class="employee__info">
        <div class="employee_name">{{ profile.first_name }}</div>
        <div class="employee__company">{{ profile.company.name }}</div>
      </div>
    </div>

    <logo class="logo" />

    <div class="actions">
      <div @click="onClickActionExit" class="action">
        <div class="action__text">Выйти из аккаунта</div>
        <exit-icon class="action__icon exit" />
      </div>
    </div>
  </div>
</template>

<style src="./Topbar.scss" lang="scss" scoped></style>

<script>
import Logo from '!vue-svg-loader!shared/assets/images/briskly-logo.svg';
import ExitIcon from '!vue-svg-loader!@/core/assets/images/exit-outline-icon.svg';
import { mapActions, mapGetters } from 'vuex';
import { bus } from 'shared/core';
import mainAPI from '@/core/api/main';

export default {
  components: {
    Logo,
    ExitIcon,
  },

  props: {
    onMount: {
      type: Function,
      required: false,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters({ profile: 'core$main/profile' }),
  },

  methods: {
    ...mapActions({
      setWelcomeStage: 'core$main/setWelcomeStage',
      setTopbarHeightAction: 'core$main/setTopbarHeightAction',
      flushCustomerDataAction: 'core$customer/flushCustomerDataAction',
      flushOrdersAction: 'core$orders/flushAction',
    }),

    async onClickActionExit() {
      await this.$router.push('/logout');
      await this.flushCustomerDataAction(false);
      await this.flushOrdersAction();
      mainAPI.setIsInitialized(false);
    },

    computeHeight() {
      const interval = setInterval(() => {
        if (this.$refs.topbar) {
          const { height } = this.$refs.topbar.getBoundingClientRect();
          this.setTopbarHeightAction(height);
          clearInterval(interval);
        }
      }, 200);
    },
  },

  mounted() {
    this.computeHeight();
  },

  updated() {
    this.computeHeight();
  },
};
</script>
