import CartAPI from '@/apps/market/api/cart';
import { cloneDeep } from 'lodash';
import router from '@/core/router';

const statuses = {
  LOADING: 'LOADING',
  HANDLED_LOADING: 'HANDLED_LOADING',
};

const state = {
  loading: false,
  order: null,
  pendingItems: {},
  pendingTimers: [],
};

const initialState = cloneDeep(state);

const getters = {
  loading: (state) => state.loading,
  order: (state) => state.order,
  values: (state) => (state.order ? state.order.items : []),
  orderItemByItemId: (state) => (id) => {
    if (!state.order) return;

    const orderItem = state.order.items.find((item) => item.item_id === id);
    if (orderItem) return orderItem;
  },
  quantityByItemId: (state) => (id) => {
    if (!state.order) return 0;
    const orderItem = state.order.items.find((item) => item.item_id === id);
    if (orderItem) return orderItem.quantity;

    return 0;
  },
  doesItemHavePendingStatus: (state) => (item, status) => state.pendingItems[item.id] === status,

  doesItemHaveHandledPending: (state) => (item) =>
    state.pendingItems[item.id] === statuses.HANDLED_LOADING,

  isPendingTimerRegistered: (state) => (id) => state.pendingTimers.includes(id),
};

const actions = {
  flushAction: ({ commit }) => {
    commit('flush');
  },
  payAction: async ({ state, commit, dispatch }) => {
    if (state.loading) return;

    try {
      commit('setLoading', true);
      const { message } = await CartAPI.pay(state.order.id);

      await dispatch('core$customer/flushCustomerDataAction', false, { root: true });

      try {
        await router.replace({ name: 'info', params: { message, temporary: true } });
      } catch {}
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  setOrderAction: ({ commit }, value) => {
    commit('setOrder', value);
  },
  addWithAmountAction: async ({ commit, state, getters }, opts = {}) => {
    const { item, quantity, addToExistingQuantity = true } = opts;

    if (state.loading) return;
    const orderId = state.order.id;

    commit('setLoading', true);

    const orderItem = getters.orderItemByItemId(item.id);

    try {
      let newOrder;

      if (orderItem) {
        let overallQuantity;

        if (addToExistingQuantity)
          overallQuantity = orderItem ? orderItem.quantity + quantity : quantity;
        else overallQuantity = quantity;

        newOrder = await CartAPI.updateItem(orderId, orderItem, overallQuantity);
      } else {
        newOrder = await CartAPI.addItem(orderId, item, quantity);
      }

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  addAction: async ({ commit, state, getters, dispatch }, value) => {
    if (state.loading) return;

    const orderId = state.order.id;
    const orderItem = getters.orderItemByItemId(value.id);

    commit('setLoading', true);

    const timerId = await dispatch('registerPendingCheckout', value);

    try {
      const newOrder = orderItem
        ? await CartAPI.increaseItem(orderId, orderItem)
        : await CartAPI.addItem(orderId, value);

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('unregisterPendingCheckout', { item: value, timerId });
      commit('setLoading', false);
    }
  },
  addWeightAction: async ({ commit, state, getters, dispatch }, opts) => {
    const { value, weight, addToExistingWeight = false } = opts;

    if (state.loading) return;

    const orderId = state.order.id;
    const orderItem = getters.orderItemByItemId(value.id);

    commit('setLoading', true);

    const timerId = await dispatch('registerPendingCheckout', value);

    try {
      const newOrder = orderItem
        ? await CartAPI.updateWeightItem(
            orderId,
            value,
            addToExistingWeight ? orderItem.weight + weight : weight,
          )
        : await CartAPI.addWeightItem(orderId, value, weight);

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('unregisterPendingCheckout', { item: value, timerId });
      commit('setLoading', false);
    }
  },
  removeAction: async ({ dispatch, commit, state, getters }, value) => {
    if (state.loading) return;

    const orderId = state.order.id;
    const orderItem = getters.orderItemByItemId(value.id);

    commit('setLoading', true);

    const timerId = await dispatch('registerPendingCheckout', value);

    try {
      const newOrder = await CartAPI.removeItem(orderId, orderItem);

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('unregisterPendingCheckout', { item: value, timerId });
      commit('setLoading', false);
    }
  },
  removeWeightAction: async ({ dispatch, commit, state, getters }, value) => {
    if (state.loading) return;

    const orderId = state.order.id;
    const orderItem = getters.orderItemByItemId(value.id);

    commit('setLoading', true);

    const timerId = await dispatch('registerPendingCheckout', value);

    try {
      const newOrder = await CartAPI.removeItem(orderId, orderItem);

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('unregisterPendingCheckout', { item: value, timerId });
      commit('setLoading', false);
    }
  },
  registerPendingCheckout: async ({ commit, getters }, item) => {
    commit('setItemPendingStatus', { item, status: statuses.LOADING });

    const timerId = setTimeout(() => {
      if (getters.doesItemHavePendingStatus(item, statuses.LOADING)) {
        commit('setItemPendingStatus', { item, status: statuses.HANDLED_LOADING });
      }
    }, 500);

    commit('addPendingTimer', timerId);

    return timerId;
  },
  unregisterPendingCheckout: async ({ commit, getters }, { item, timerId }) => {
    if (getters.isPendingTimerRegistered(timerId)) {
      commit('removePendingTimer', timerId);
      commit('setItemPendingStatus', { item, status: null });
    }
  },
};

const mutations = {
  flush: (state) => {
    Object.assign(state, cloneDeep(initialState));
  },
  setOrder: (state, value) => {
    state.order = value;
  },
  add: (state, value) => {
    state.values = state.values.concat(value);
  },
  remove: (state, value) => {
    const index = state.values.findIndex((v) => value.id === v.id);
    const values = state.values.slice();
    values.splice(index, 1);
    state.values = values;
  },
  setLoading: (state, value) => {
    state.loading = value;
  },
  setItemPendingStatus: (state, { item, status }) => {
    state.pendingItems = {
      ...state.pendingItems,
      [item.id]: status,
    };
  },
  addPendingTimer: (state, timer) => {
    state.pendingTimers = state.pendingTimers.concat(timer);
  },
  removePendingTimer: (state, timer) => {
    state.pendingTimers = state.pendingTimers.filter((t) => t !== timer);
    clearTimeout(timer);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
