<template>
  <div class="welcome">
    <bar-title>Новый Заказ</bar-title>

    <block>
      <action-label>Номер телефона</action-label>
      <action-input
        @keydown.enter.native="onAddressEnter"
        v-mask="'+7 (###) ###-##-##'"
        placeholder="+7 (495) 123-45-67"
        :onChange="onChangePhone"
        :value="phone"
      />
    </block>

    <action-button @click.native="onClickNext" class="new-order-button">Новый заказ</action-button>
  </div>
</template>

<style src="@/core/styles/shared.scss" lang="scss" scoped></style>
<style src="./Welcome.scss" lang="scss" scoped></style>

<script>
import { mapGetters, mapActions } from 'vuex';
import ActionButton from '@/core/components/atoms/ActionButton/ActionButton';
import ActionLabel from '@/core/components/atoms/ActionLabel/ActionLabel';
import ActionInput from '@/core/components/atoms/ActionInput/ActionInput';
import Block from '@/core/components/atoms/Block/Block';
import BarTitle from '@/core/components/atoms/BarTitle/BarTitle';
import { mask } from 'vue-the-mask';

export default {
  directives: { mask },
  components: {
    ActionButton,
    ActionLabel,
    ActionInput,
    Block,
    BarTitle,
  },

  computed: {
    ...mapGetters({
      phone: 'core$customer/phone',
    }),
  },

  methods: {
    ...mapActions({
      setPhoneAction: 'core$customer/setPhoneAction',
      createOrderAction: 'core$customer/createOrderAction',
    }),

    onChangePhone(ev) {
      let { value } = ev.currentTarget;

      if (value.length && value[0] === '8') {
        value = '+7' + value.slice(1);
        ev.currentTarget.value = value;
      }

      this.setPhoneAction(value);
    },

    onAddressEnter() {
      this.onClickNext();
    },

    onClickNext() {
      if (!this.phone) return;
      this.createOrderAction();
    },
  },

  mounted() {
    this.setPhoneAction('');
  },
};
</script>
