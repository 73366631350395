import { api } from 'shared/core';
// import CartAPI from './cart';
import store from '@/core/store';

class MainAPI {
  async init({ store_id, order_id }) {
    try {
      await this.selectStore({ store_id, order_id });
      await store.dispatch('market$categories/fetchRootCategoriesAction');

      switch (store.getters['market$categories/size']) {
        case 0:
          {
            await store.dispatch('market$categories/fetchUncategorizedProducts');
            try {
              await router.push({ name: 'market$products', params: { category: 0 } });
            } catch {}
          }
          break;
        case 1:
          {
            const category = store.getters['market$categories/rootCategories'][0];
            await store.dispatch('market$categories/fetchSubCategoriesAction', category.info.id);
            try {
              await router.push({
                name: 'market$products',
                params: { category: category.info.id },
              });
            } catch {}
          }
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  selectStore({ store_id, order_id }) {
    return api.endpoint.post('dashboard/concierge/order/store/select', {
      id: order_id,
      store_id: store_id,
    });
  }

  async getUncategorizedProducts(opts = {}) {
    const { page = 1, limit = 20 } = opts;

    return api.endpoint.get('dashboard/selected-store/catalog/item/get-list', {
      page,
      limit,
    });
  }

  async getCategoriesWithProducts(opts = {}) {
    const {
      items_page = 1,
      items_limit = 20,
      categories_page = 1,
      categories_limit = 20,
      category_id,
    } = opts;

    return api.endpoint.get('dashboard/selected-store/catalog/get-categories-and-items', {
      items_page,
      items_limit,
      categories_page,
      categories_limit,
      category_id,
    });
  }
}

export default new MainAPI();
