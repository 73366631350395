<template>
  <div class="order-bar">
    <div class="header">
      <div class="search-container" v-if="search">
        <input ref="search" class="search" type="text" />
        <search-icon @click="onClickSearch(false)" class="search-icon" />
      </div>
      <div v-else class="title-container">
        <bar-title>Заявки</bar-title>
        <!-- <search-icon @click="onClickSearch(true)" class="search-icon" /> -->
      </div>
    </div>

    <div class="body">
      <div class="order-list" :key="order.name" v-for="order in serializedOrders">
        <div @click="onListDropdown(order.name)" class="order-list__title">
          <span>{{ localizeOrderName(order.name) }} ({{ getOrdersAmountByType(order.name) }})</span>

          <arrow-bottom-icon
            :class="{ 'order-list__title-dropdown-icon': true, show: isListShown(order.name) }"
          />
        </div>

        <ul v-if="isListShown(order.name)" class="order-list__items">
          <li
            v-for="item in order.items"
            :key="item.id"
            :class="{ 'order-list__item': true, active: isOrderItemActive(item) }"
            @click="onClickOrderItem(item, order.name)"
            >Заказ №{{ item.id }}</li
          >
        </ul>
      </div>
    </div>
  </div>
</template>

<style src="@/core/styles/shared.scss" lang="scss" scoped></style>
<style src="./OrderBar.scss" lang="scss" scoped></style>

<script>
import { bus } from 'shared/core';
import SearchIcon from '!vue-svg-loader!shared/modules/personal/images/search-icon.svg';
import ArrowBottomIcon from '!vue-svg-loader!@/core/assets/images/arrow-bottom-icon.svg';
import BarTitle from '@/core/components/atoms/BarTitle/BarTitle';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    SearchIcon,
    ArrowBottomIcon,
    BarTitle,
  },

  data() {
    return {
      search: false,
      listState: {},
    };
  },

  computed: {
    ...mapGetters({ orders: 'core$orders/orders', activeOrder: 'core$orders/activeOrder' }),

    serializedOrders() {
      const result = [];

      for (const [k, v] of this.orders.entries()) {
        result.push({ name: k, items: v.items });
      }

      return result;
    },
  },

  methods: {
    ...mapActions({
      setActiveOrderAction: 'core$orders/setActiveOrderAction',
    }),

    localizeOrderName(name) {
      switch (name) {
        case 'checker_awaiting':
          return 'Активные';

        case 'checker_accepted':
          return 'Сборка';

        case 'checker_archive':
          return 'Архив';
      }
    },

    onListDropdown(type) {
      this.listState = {
        ...this.listState,
        [type]: !this.listState[type],
      };
    },

    onClickSearch(value) {
      this.search = value;

      setTimeout(() => {
        value && this.$refs.search && this.$refs.search.focus();
      });
    },

    onClickOrderItem(item, preset) {
      this.setActiveOrderAction(item.id).then(() => {
        const params = {
          name: 'order',
          params: {
            order: item.id,
            preset,
          },
        };

        if (this.$route.name === 'order') this.$router.replace(params).catch(() => {});
        else this.$router.push(params).catch(() => {});
      });
    },

    isOrderItemActive(item) {
      if (!this.activeOrder) return false;
      return this.activeOrder === item.id;
    },

    isListShown(type) {
      return !!this.listState[type];
    },

    getOrdersAmountByType(type) {
      return this.orders.get(type).items.length;
    },
  },
};
</script>
