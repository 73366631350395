export default [
  {
    path: 'market',
    name: 'main',
    redirect: '/market/categories',
    component: () => import('@/apps/market/views/Main/Main'),
    children: [
      {
        path: '/search',
        name: 'search',
        component: () => import('@/apps/market/views/Search/Search'),
      },

      {
        path: 'cart',
        name: 'cart',
        component: () => import('@/apps/market/views/Cart/Cart'),
      },

      {
        path: 'categories/:category/products/:product',
        name: 'product',
        component: () => import('@/apps/market/views/Product/Product'),
      },

      {
        path: 'categories/:category/products',
        name: 'products',
        component: () => import('@/apps/market/views/Products/Products'),
      },

      {
        path: 'categories*',
        name: 'categories',
        component: () => import('@/apps/market/views/Categories/Categories'),
      },
    ],
  },
];
