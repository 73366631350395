import mainAPI from '@/core/api/main';

const STAGES = {
  welcome: 'welcome',
  newOrder: 'new-order',
  createOrder: 'create-order',
};

const state = {
  stage: STAGES.welcome,
  loading: true,
  topbarHeight: 0,
  profile: null,
};

const getters = {
  stage: (state) => state.stage,
  isWelcomeStage: (state) => state.stage === STAGES.welcome,
  isNewOrderStage: (state) => state.stage === STAGES.newOrder,
  isCreateOrderStage: (state) => state.stage === STAGES.createOrder,
  loading: (state) => state.loading,
  topbarHeight: (state) => state.topbarHeight,
  profile: (state) => state.profile,
};

const actions = {
  setWelcomeStageAction: ({ commit }) => {
    commit('setStage', STAGES.welcome);
  },

  setNewOrderStageAction: ({ commit }) => {
    commit('setStage', STAGES.newOrder);
  },

  setCreateOrderStageAction: ({ commit }) => {
    commit('setStage', STAGES.createOrder);
  },

  setLoadingAction: ({ commit }, value) => {
    commit('setLoading', value);
  },

  setTopbarHeightAction: ({ commit }, value) => {
    commit('setTopbarHeight', value);
  },

  fetchProfile: async ({ commit, getters }) => {
    if (getters.profile) return;

    try {
      const profile = await mainAPI.getProfile();
      commit('setProfile', profile);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setStage: (state, value) => {
    state.stage = value;
  },

  setProfile: (state, value) => {
    state.profile = value;
  },

  setLoading: (state, value) => {
    state.loading = value;
  },

  setTopbarHeight: (state, value) => {
    state.topbarHeight = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
