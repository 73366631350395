import mainAPI from '@/core/api/main';
import { cloneDeep } from 'lodash';

const ORDER_PRESETS = ['checker_awaiting', 'checker_accepted', 'checker_archive'];

const state = {
  activeOrder: null,
  orders: new Map([
    ['checker_awaiting', { items: [], meta: {} }],
    ['checker_accepted', { items: [], meta: {} }],
    ['checker_archive', { items: [], meta: {} }],
  ]),
};

const initialState = cloneDeep(state);

const getters = {
  activeOrder: (state) => state.activeOrder,
  orders: (state) => state.orders,
  ordersByPreset: (state) => (preset) => state.orders.get(preset),
};

const actions = {
  flushAction: ({ commit }) => {
    commit('flush');
  },

  setActiveOrderAction: ({ commit }, value) => {
    commit('setActiveOrder', value);
  },

  fetchInitialOrders: async ({ dispatch }) => {
    ORDER_PRESETS.forEach((preset) => dispatch('fetchOrders', preset));
  },

  updateOrderAction: async ({ commit }, { preset, order }) => {
    commit('updateOrder', { preset, order });
  },

  fetchOrders: async ({ commit }, preset) => {
    const orders = await mainAPI.getOrderList({ preset });
    commit('addOrders', { orders, preset });
  },
};

const mutations = {
  flush: (state) => {
    Object.assign(state, cloneDeep(initialState));
  },

  setActiveOrder: (state, value) => {
    state.activeOrder = value;
  },

  addOrders: (state, { orders, preset }) => {
    const actualOrders = state.orders.get(preset);
    const updatedOrders = new Map([...state.orders]);

    const allItems = [...orders.items, ...actualOrders.items];
    const uniqueIds = new Set(allItems.map((o) => o.id));
    const updatedItems = [...uniqueIds].map((id) => allItems.find((item) => item.id === id));

    const payload = {
      items: updatedItems,
      meta: orders.meta,
    };

    updatedOrders.set(preset, payload);

    state.orders = updatedOrders;
  },

  updateOrder: (state, { order, preset }) => {
    const updatedOrders = new Map([...state.orders]);

    ORDER_PRESETS.forEach((orderPreset) => {
      const targetOrders = { ...updatedOrders.get(orderPreset) };

      if (orderPreset === preset) {
        const index = targetOrders.items.findIndex((item) => item.id === order.id);

        index !== -1
          ? targetOrders.items.splice(index, 1, order)
          : targetOrders.items.unshift(order);
      } else {
        targetOrders.items = targetOrders.items.filter((item) => item.id !== order.id);
      }

      updatedOrders.set(orderPreset, targetOrders);
    });

    state.orders = updatedOrders;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
