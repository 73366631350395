import mainAPI from '@/apps/market/api/main';
import { cloneDeep } from 'lodash';

const state = {
  // [id]: {
  //   info: {},
  //   meta: {},
  // },
  allCategories: new Map(),

  // [id]: {
  //   items: [],
  //   meta: {},
  // },
  allProducts: new Map(),

  rootMeta: {},
  fetchLoading: false,
};

const initialState = cloneDeep(state);

const getters = {
  rootCategories: (state) => {
    const result = [];

    for (const value of state.allCategories.values()) {
      !value.info.parent_id && result.push(value);
    }

    return result;
  },

  subCategories: (state) => (parentId) => {
    const result = [];

    for (const value of state.allCategories.values()) {
      value.info.parent_id === parentId && result.push(value);
    }

    return result;
  },

  categoryProducts: (state) => (categoryId) => {
    const target = state.allProducts.get(categoryId);
    return target ? target.items : [];
  },

  productById: (state) => (id) => {
    let result = [];

    for (const value of state.allProducts.values()) {
      result = result.concat(value.items);
    }

    return result.find((p) => p.id === id);
  },

  rootMeta: (state) => state.rootMeta,
  categoryById: (state) => (id) => state.allCategories.get(id),
  size: (state) => state.allCategories.size,
  fetchLoading: (state) => state.fetchLoading,
};

const actions = {
  flushAction: ({ commit }) => {
    commit('flush');
  },

  addRootCategoriesAction: ({ commit }, { categories }) => {
    commit('addCategories', categories.items);
    commit('setRootMeta', categories.meta);
  },

  addCategoriesAction: ({ commit }, { id, items, categories }) => {
    commit('addCategories', categories.items);
    commit('addProducts', { id, products: items });
    commit('setCategoryMeta', { id, meta: categories.meta });
  },

  addUncategorizedProductsAction: ({ commit }, { items, meta }) => {
    commit('addUncategorizedProducts', { items, meta });
  },

  fetchRootCategoriesAction: async ({ commit, getters, dispatch }) => {
    try {
      if (state.fetchLoading) return;

      const meta = getters.rootMeta;
      let response;

      commit('setFetchLoading', true);

      if (!meta) {
        response = await mainAPI.getCategoriesWithProducts();
      } else {
        if (meta.page >= meta.total_pages) return;

        const payload = {
          categories_page: meta.page + 1 || 1,
          categories_limit: meta.limit,
        };

        response = await mainAPI.getCategoriesWithProducts(payload);
      }

      await dispatch('addRootCategoriesAction', response);

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      commit('setFetchLoading', false);
    }
  },

  fetchSubCategoriesAction: async ({ commit, state, getters, dispatch }, id) => {
    try {
      if (state.fetchLoading) return;

      const { meta } = getters.categoryById(id) || {};

      let response;

      commit('setFetchLoading', true);

      if (!meta) {
        const payload = {
          category_id: id,
        };
        response = await mainAPI.getCategoriesWithProducts(payload);
      } else {
        if (meta.page >= meta.total_pages) return true;

        const payload = {
          category_id: id,
          categories_page: meta.page + 1 || 1,
          categories_limit: meta.limit,
        };

        response = await mainAPI.getCategoriesWithProducts(payload);
      }

      await dispatch('addCategoriesAction', { ...response, id });

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      commit('setFetchLoading', false);
    }
  },

  fetchUncategorizedProducts: async ({ commit, state, dispatch }) => {
    try {
      if (state.fetchLoading) return;

      const { meta } = state.allProducts.get(0) || {};

      let response;

      commit('setFetchLoading', true);

      if (!meta) {
        response = await mainAPI.getUncategorizedProducts();
      } else {
        if (meta.page >= meta.total_pages) return true;

        const payload = {
          page: meta.page + 1 || 1,
          limit: meta.limit,
        };

        response = await mainAPI.getUncategorizedProducts(payload);
      }

      await dispatch('addUncategorizedProductsAction', response);

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      commit('setFetchLoading', false);
    }
  },

  fetchCategoryItems: async ({ commit, dispatch, state, getters }, id) => {
    try {
      if (state.fetchLoading) return;

      const { meta } = state.allProducts.get(id) || {};
      const { meta: categoryMeta } = getters.categoryById(id) || {};

      let response;

      commit('setFetchLoading', true);

      if (!meta) {
        const payload = {
          category_id: id,
        };

        response = await mainAPI.getCategoriesWithProducts(payload);
      } else {
        if (meta.page >= meta.total_pages) return true;

        const payload = {
          category_id: id,
          categories_page: categoryMeta.page,
          categories_limit: categoryMeta.limit,
          items_page: meta.page + 1 || 1,
          items_limit: meta.limit,
        };

        response = await mainAPI.getCategoriesWithProducts(payload);
      }

      await dispatch('addCategoriesAction', { ...response, id });

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      commit('setFetchLoading', false);
    }
  },
};

const mutations = {
  addCategories: (state, categories) => {
    const newCategories = new Map();
    categories.forEach((c) => newCategories.set(c.id, { info: c }));

    state.allCategories = new Map([...state.allCategories, ...newCategories]);
  },

  addProducts: (state, { id, products }) => {
    const cached = state.allProducts.get(id);
    const newProducts = new Map([...state.allProducts]);

    if (!cached) {
      newProducts.set(id, products);
      state.allProducts = newProducts;

      return;
    }

    const { items, meta } = products;

    // TODO: maybe remove duplicates
    const updated = {
      items: [...cached.items, ...items],
      meta,
    };

    newProducts.set(id, updated);

    state.allProducts = newProducts;
  },

  addUncategorizedProducts: (state, { items, meta }) => {
    const rootCategory = state.allCategories.get(0);
    const newCategories = new Map([...state.allCategories]);

    if (!rootCategory) {
      newCategories.set(0, {
        info: { id: 0, name: 'Товары' },
        meta: { page: 1, limit: 20, total_pages: 1 },
      });

      state.allCategories = newCategories;
    }

    const cachedProduct = state.allProducts.get(0);
    const newProducts = new Map([...state.allProducts]);

    if (!cachedProduct) {
      newProducts.set(0, { items, meta });
      state.allProducts = newProducts;
      return;
    }

    const newProduct = {
      items: [...cachedProduct.items, ...items],
      meta,
    };

    newProducts.set(0, newProduct);

    state.allProducts = newProducts;
  },

  setRootMeta: (state, meta) => {
    state.rootMeta = meta;
  },

  setCategoryMeta: (state, { id, meta }) => {
    const category = state.allCategories.get(id);
    category.meta = meta;

    const updatedCategories = new Map([...state.allCategories]);
    updatedCategories.set(id, category);

    state.allCategories = updatedCategories;
  },

  setFetchLoading: (state, value) => {
    if (state.fetchLoading !== value) {
      state.fetchLoading = value;
    }
  },

  flush: (state) => {
    Object.assign(state, cloneDeep(initialState));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
