<template>
  <input :disabled="disabled" @input="onChange" :value="value" class="action-input" type="text" />
</template>

<style src="./ActionInput.scss" lang="scss" scoped></style>

<script>
export default {
  props: {
    onChange: {
      type: Function,
      default: () => {},
    },

    value: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
