<template>
  <div class="action-bar">
    <component :is="computeComponent" />
  </div>
</template>

<style src="./ActionBar.scss" lang="scss" scoped></style>

<script>
import { mapGetters, mapActions } from 'vuex';
import Welcome from './Welcome/Welcome';
import NewOrder from './NewOrder/NewOrder';

export default {
  components: {
    Welcome,
    NewOrder,
  },

  computed: {
    ...mapGetters({ stage: 'core$main/stage' }),

    computeComponent() {
      if (this.stage === 'welcome') return 'welcome';
      return 'new-order';
    },
  },
};
</script>
