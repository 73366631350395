<template>
  <div class="main">
    <topbar />

    <section :style="{ height: `calc(100vh - ${topbarHeight}px)` }" class="content">
      <action-bar />
      <div id="main-view" :class="{veil: canShowVeil}">
        <router-view />
      </div>
      <order-bar />
    </section>
  </div>
</template>

<style src="./Main.scss" lang="scss" scoped></style>

<script>
import Topbar from '@/core/components/organisms/Topbar/Topbar';
import OrderBar from '@/core/components/organisms/OrderBar/OrderBar';
import ActionBar from '@/core/components/organisms/ActionBar/ActionBar';
import { mapGetters, mapActions } from 'vuex';
import mainAPI from '@/core/api/main';

export default {
  components: {
    Topbar,
    OrderBar,
    ActionBar,
  },

  data() {
    return {
      height: 0,
    };
  },

  computed: {
    ...mapGetters({
      isWelcomeStage: 'core$main/isWelcomeStage',
      isNewOrderStage: 'core$main/isNewOrderStage',
      topbarHeight: 'core$main/topbarHeight',
    }),

    canShowVeil() {
      return this.isWelcomeStage || this.isNewOrderStage;
    },
  },

  methods: {
    ...mapActions({
      fetchProfile: 'core$main/fetchProfile',
    }),
  },

  mounted() {
    mainAPI.init();
  },
};
</script>
