import { render, staticRenderFns } from "./BlockTitle.vue?vue&type=template&id=fc459556&scoped=true&"
var script = {}
import style0 from "./BlockTitle.scss?vue&type=style&index=0&id=fc459556&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../home/gitlab-runner/.nvm/versions/node/v12.14.1/lib/node_modules/@briskly/vue-cli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc459556",
  null
  
)

export default component.exports