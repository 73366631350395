import { bus } from 'shared/core';
import store from '@/core/store';

class OrderEvent {
  init = () => {
    bus.on('order', this.onOrder);
  };

  onOrder = (order) => {
    const [target] = Object.keys(order.presets)
      .filter((preset) => preset === 'checker')
      .filter((preset) => order.presets[preset]);

    if (target) {
      const preset = order.presets[target];
      store.dispatch('core$orders/updateOrderAction', { preset, order });
    }
  };
}

export default new OrderEvent();
