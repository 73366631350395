<template>
  <div v-if="order" class="new-order">
    <bar-title>Новый заказ №{{ order.id }}</bar-title>
    <block>
      <block-title>информация о клиенте</block-title>
      <action-label>Номер телефона</action-label>
      <action-input
        v-mask="'+7 (###) ###-##-##'"
        placeholder="+7 (495) 123-45-67"
        :disabled="isCreateOrderStage"
        :onChange="onChangePhone"
        :value="customer.phone"
      />
    </block>

    <block>
      <action-label>Фамилия</action-label>
      <action-input
        :disabled="isCreateOrderStage"
        :onChange="onChangeLastName"
        :value="customer.last_name"
      />
    </block>

    <block>
      <action-label>Имя</action-label>
      <action-input
        :disabled="isCreateOrderStage"
        :onChange="onChangeFirstName"
        :value="customer.first_name"
      />
    </block>

    <block class="relative">
      <block-title>адрес доставки</block-title>

      <line-input
        :onEnter="onAddressEnter"
        ref="address"
        :disabled="isCreateOrderStage"
        :value="order.delivery.address"
        :onChange="onChangeAddress"
        :error="errors.address"
        wide
        placeholder="Название города и улицы"
      />

      <div class="select-menu" v-if="availableAddresses.length">
        <div
          v-for="(a, i) in availableAddresses"
          :key="i"
          @click="onClickDirectAddress(a.address)"
        >{{ a.address }}</div>
      </div>
    </block>

    <block class="address-block">
      <line-input
        :disabled="isCreateOrderStage"
        :value="order.delivery.address_apartment"
        :onChange="onChangeApartment"
        placeholder="Квартира"
      />
      <line-input
        :disabled="isCreateOrderStage"
        :value="order.delivery.address_entrance"
        :onChange="onChangeEntrance"
        placeholder="Подъезд"
      />
      <line-input
        :disabled="isCreateOrderStage"
        :value="order.delivery.address_floor"
        :onChange="onChangeFloor"
        placeholder="Этаж"
      />
    </block>

    <block>
      <line-input
        :disabled="isCreateOrderStage"
        :onChange="onChangeNote"
        :value="order.delivery.note"
        wide
        placeholder="Комментарий для курьера"
      />
    </block>

    <block class="relative nearest-store">
      <block-title>адрес ближайшего магазина</block-title>

      <line-input
        :value="nearestStoreName"
        disabled
        :clickable="hasManyNearestStores"
        :hasIcon="hasManyNearestStores"
        wide
        placeholder="Определится автоматически"
      />

      <arrow-bottom-icon
        @click="onToggleNearestStoresMenu"
        v-if="hasManyNearestStores"
        class="select-menu-arrow"
      />

      <div class="select-menu" v-if="hasManyNearestStores && showNearestStores">
        <div v-for="(store, i) in nearestStores" :key="i" @click="onClickNearestStore(store)">
          <div>{{ store.name }}</div>
          <div class="subtitle">{{ store.address }}</div>
        </div>
      </div>
    </block>

    <block class="actions-block">
      <action-button
        @click.native="onClickNext"
        v-if="!isCreateOrderStage"
        :disabled="!canGoNext"
      >Сформировать заказ</action-button>

      <action-button @click.native="onClickChangeOrder" v-if="isCreateOrderStage">Изменить</action-button>

      <!-- <action-button v-if="isCreateOrderStage" @click.native="onClickDownloadPackingPaper"
        >Скачать накладную</action-button
      >-->

      <action-button class="flush-button" @click.native="onClickFlush">Отменить</action-button>
    </block>
  </div>
</template>

<style src="./NewOrder.scss" lang="scss" scoped></style>

<script>
import BarTitle from '@/core/components/atoms/BarTitle/BarTitle';
import BlockTitle from '@/core/components/atoms/BlockTitle/BlockTitle';
import Block from '@/core/components/atoms/Block/Block';
import ActionLabel from '@/core/components/atoms/ActionLabel/ActionLabel';
import ActionInput from '@/core/components/atoms/ActionInput/ActionInput';
import LineInput from '@/core/components/atoms/LineInput/LineInput';
import ActionButton from '@/core/components/atoms/ActionButton/ActionButton';
import ArrowBottomIcon from '!vue-svg-loader!@/core/assets/images/arrow-bottom-icon.svg';
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'lodash';
import mainAPI from '@/core/api/main';
import { mask } from 'vue-the-mask';

export default {
  directives: { mask },
  components: {
    BarTitle,
    BlockTitle,
    Block,
    ActionLabel,
    ActionInput,
    LineInput,
    ActionButton,
    ArrowBottomIcon,
  },

  data() {
    return {
      availableAddresses: [],
      showNearestStores: false,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'core$customer/loading',
      order: 'core$customer/order',
      isNewOrderStage: 'core$main/isNewOrderStage',
      isCreateOrderStage: 'core$main/isCreateOrderStage',
      customer: 'core$customer/customer',
      canDelivery: 'core$customer/canDelivery',
      errors: 'core$customer/errors',
      nearestStore: 'core$customer/nearestStore',
      nearestStores: 'core$customer/nearestStores',
    }),

    canGoNext() {
      if (this.loading) return;
      if (!this.canDelivery) return;
      if (!this.nearestStore) return;

      return true;
    },

    hasManyNearestStores() {
      return this.nearestStores.length > 1;
    },

    nearestStoreName() {
      if (!this.nearestStore) return '';
      return this.nearestStore.name;
    },
  },

  methods: {
    ...mapActions({
      updateInfoAction: 'core$customer/updateInfoAction',
      setLoadingAction: 'core$customer/setLoadingAction',
      setCanDeliveryAction: 'core$customer/setCanDeliveryAction',
      fetchDirectAddressAction: 'core$customer/fetchDirectAddressAction',
      confirmCustomerCredentialsAction: 'core$customer/confirmCustomerCredentialsAction',
      flushCustomerDataAction: 'core$customer/flushCustomerDataAction',
      setCreateOrderStageAction: 'core$main/setCreateOrderStageAction',
      setNewOrderStageAction: 'core$main/setNewOrderStageAction',
      setWelcomeStageAction: 'core$main/setWelcomeStageAction',
      setNearestStoreAction: 'core$customer/setNearestStoreAction',
    }),

    fetchValues: debounce(function(value) {
      mainAPI.getAddress({ q: value }).then(r => {
        this.availableAddresses = r;
      });
    }, 1000),

    onClickFlush() {
      this.flushCustomerDataAction();
    },

    onToggleNearestStoresMenu() {
      this.showNearestStores = !this.showNearestStores;
    },

    onChangeApartment(ev) {
      this.updateInfoAction({ name: 'apartment', value: ev.currentTarget.value });
    },

    onChangeEntrance(ev) {
      this.updateInfoAction({ name: 'entrance', value: ev.currentTarget.value });
    },

    onChangeFloor(ev) {
      this.updateInfoAction({ name: 'floor', value: ev.currentTarget.value });
    },

    onChangeNote(ev) {
      this.updateInfoAction({ name: 'note', value: ev.currentTarget.value });
    },

    onChangeAddress(ev) {
      this.setLoadingAction(true);
      this.fetchValues(ev.currentTarget.value);
      this.updateInfoAction({ name: 'address', value: ev.currentTarget.value });
    },

    onClickDirectAddress(address) {
      this.availableAddresses = [];
      this.fetchDirectAddressAction(address);
    },

    onChangePhone(ev) {
      this.updateInfoAction({ name: 'phone', value: ev.currentTarget.value });
    },

    onChangeFirstName(ev) {
      this.updateInfoAction({ name: 'firstName', value: ev.currentTarget.value });
    },

    onChangeLastName(ev) {
      this.updateInfoAction({ name: 'lastName', value: ev.currentTarget.value });
    },

    async onClickNearestStore(store) {
      this.onToggleNearestStoresMenu();
      this.setNearestStoreAction(store);
    },

    onClickChangeOrder() {
      this.setNewOrderStageAction();
    },

    onClickDownloadPackingPaper() {
      const { id: order_id } = this.order;

      mainAPI
        .getPackingPaper({ order_id })
        .then(({ link }) => window.open(link, '_blank'))
        .catch(e => console.error(e));
    },

    onClickAnywhere(ev) {
      if (!ev.target) return;
      if (!ev.target.closest) return;
      if (!this.showNearestStores) return;

      if (!ev.target.closest('.nearest-store')) {
        this.showNearestStores = false;
      }
    },

    onAddressEnter() {
      this.onClickNext();
    },

    async onClickNext() {
      if (!this.canGoNext) return;
      // TODO: проверить, можно ли переключаться:
      // дистанция, все данные. если ок, тогда делаем select-store и переключаемся на маркет

      await this.confirmCustomerCredentialsAction();
      this.setCreateOrderStageAction();
    },
  },

  mounted() {
    window.addEventListener('click', this.onClickAnywhere);

    if (this.$refs.address && this.$refs.address.$refs.input) {
      this.$refs.address.$refs.input.focus();
    }

    if (this.order.delivery.address) {
      this.fetchDirectAddressAction(this.order.delivery.address);
    }
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickAnywhere);
  },
};
</script>
