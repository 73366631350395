import 'animate.css';
import 'normalize.css';
import '@/core/styles.scss';
import 'regenerator-runtime';

import Vue from 'vue';
import Vuex from 'vuex';
import { api, bus, Channel, utils } from 'shared/core';
import { auth, personal } from 'shared/modules';
import App from '@/core/App';
import router from '@/core/router';
import store from '@/core/store';

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.prototype.$api = api;
Vue.prototype.$bus = bus;

api.setEndpoint('business/checker');

utils.getEvents().forEach(({ value }) => value.init(store));
utils.getChannels().forEach(({ value }) => new Channel(value));

personal.connect();
auth.connect({ store, router });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
