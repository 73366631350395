import { render, staticRenderFns } from "./OrderBar.vue?vue&type=template&id=1f560698&scoped=true&"
import script from "./OrderBar.vue?vue&type=script&lang=js&"
export * from "./OrderBar.vue?vue&type=script&lang=js&"
import style0 from "@/core/styles/shared.scss?vue&type=style&index=0&id=1f560698&lang=scss&scoped=true&"
import style1 from "./OrderBar.scss?vue&type=style&index=1&id=1f560698&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../home/gitlab-runner/.nvm/versions/node/v12.14.1/lib/node_modules/@briskly/vue-cli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f560698",
  null
  
)

export default component.exports