import { api } from 'shared/core';

class SearchAPI {
  get(input = '', opts = {}) {
    const {
      categoriesPage = 1,
      categoriesLimit = 20,
      itemsPage = 1,
      itemsLimit = 20,
      categoryId,
    } = opts;

    return new Promise(async (resolve) => {
      const [products, categories] = await Promise.all([
        api.endpoint.get('dashboard/selected-store/catalog/item/get-list', {
          q: input,
          use_smart_search: 1,
          page: itemsPage,
          limit: itemsLimit,
          category_id: categoryId,
        }),

        api.endpoint.get('dashboard/selected-store/catalog/category/get-list/', {
          q: input,
          use_smart_search: 1,
          page: categoriesPage,
          limit: categoriesLimit,
          category_id: categoryId,
        }),
      ]);

      resolve({ products, categories });
    });
  }

  getProducts(input = '', opts = {}) {
    const { page = 1, limit = 20 } = opts;

    return api.endpoint.get('dashboard/selected-store/catalog/item/get-list', {
      page,
      limit,
      q: input,
      use_smart_search: 1,
    });
  }
}

export default new SearchAPI();
